<template>
    <client-page>
        
        <sub-visual
        sh="제품 및 솔루션"
        sc="에프원시큐리티는 다방면으로 파트너의 정보를 보호합니다"
        tabActive="F1 WEBCASTLE"
        icon="/images/sub/visual/sv3-icon.svg"
        bg="/images/sub/visual/sv3.png">
        </sub-visual>
        
        <section class="sub-section-sm sub-section-sm--first">
            <v-container>
                <div class="section-title mb-32 mb-lg-56">
                    <h3 class="sub-tit--lg text-center mb-30 mb-lg-44" data-aos="fade-up">
                        웹 방화벽 <br class="d-none d-lg-block">
                        Web Application Firewall (WAF)이란?
                    </h3>
                    <p class="sub-tit--sm mb-12 mb-lg-22 text-center">
                        네트워크 방화벽(Firewall)과는 달리 웹 애플리케이션 보안에 특화되어, SQL Injection, XSS등이라 불리는 <br class="d-none d-lg-block">
                        웹사이트나 서버에 대한 공격들을 탐지하고 대응 및 차단하는 보안 방식입니다.
                    </p>
                    <p class="sub-tit--sm mb-12 mb-lg-22 text-center">
                        기존의 웹 공격 대응 기능 외에도, 정보유출방지, 부정접근차단, 파일 등의 위변조 방지 등의 기능을 수행합니다. <br>
                        웹 어플리케이션 방어에 특화 개발되어 있어, 웹 보안 취약점의 노출방지 및 악성코드가 외부에서 업로드되는 것을 차단합니다. <br class="d-none d-lg-block">
                        (웹취약점 노출 방지는 원래 웹스캐너, 시큐어코딩의 기능입니다.) 

                    </p>
                </div>

                <v-row>
                    <v-col cols="12" lg="6" class="mb-20 mb-lg-0">
                        <div class="radius-lg bg-grey h-100">
                            <p class="w-100 bg-point color-white text-center font-weight-bold page-text-lg pa-14">
                                Appliance 기반의 웹 방화벽 (Hardware)
                            </p>
                            <div class="pa-16 pt-md-22 pb-md-80 px-md-42">
                                <v-img class="d-block mb-28 mb-lg-48" max-width="498" src="/images/sub/products/web/s1-img1.png"></v-img>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · Appliance  방식
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 다른 보안 제품과 같이 독립적인 Box 형태
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 시간당 트래픽 처리 용량으로 성능이 구분됨
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm">
                                    · 웹 서버 앞단에 위치하며 복수의 웹 서버 존재시 L4스위치 앞단에 위치
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="radius-lg bg-grey h-100">
                            <p class="w-100 bg-point color-white text-center font-weight-bold page-text-lg pa-14">
                                Software 기반의 웹 방화벽 (Software)
                            </p>
                            <div class="pa-16 pt-md-22 pb-md-80 px-md-42">
                                <v-img class="d-block mb-28 mb-lg-48" max-width="498" src="/images/sub/products/web/s1-img2.png"></v-img>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 웹 보안 filter방식
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 웹 서버에 직접 탑재하는 소프트웨어 방식
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 웹 방화벽의 성능은 웹 서버의 용량에 의해 결정 되어짐
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm">
                                    · 웹 방화벽 설치 시, 기존 네트워크 구성에는 변화가 없음
                                </p>
                            </div>
                        </div>
                    </v-col>
                </v-row>

            </v-container>
        </section>

        <section class="sub-section-sm">
            <v-container>

                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    웹 방화벽(WAF) 방식의 비교
                </h3>

                <v-row>
                    <v-col cols="12" lg="6" class="mb-20 mb-lg-0">
                        <div class="radius-lg bg-grey h-100">
                            <p class="w-100 bg-point color-white text-center font-weight-bold page-text-lg pa-14">
                                클라우드 전용 웹 방화벽 (Software)
                            </p>
                            <div class="pa-16 pb-0 px-md-42 pt-md-70">
                                <v-img class="d-block mx-auto " max-width="469" src="/images/sub/products/web/s2-img1.png"></v-img>
                            </div>
                            <div class="pa-16 px-md-40 pt-md-40 px-lg-70 pt-lg-70 pb-md-100">
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 웹 서버에 위치하여 서버의 내부 자원인 CPU(3%이하),  <br class="d-none d-lg-block">
                                    최소한의 서버 리소스 이용
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm mb-6 mb-lg-16">
                                    · 웹 서버에서 한번의 Connection 으로 Request Data 검사, <br class="d-none d-lg-block">
                                    프로세스 처리, Response Data에 대한 검사함으로써  <br class="d-none d-lg-block">
                                    웹 방화벽 기능으로 인한 웹 서비스 응답 속도의 지연 현상을 <br class="d-none d-lg-block">
                                    최소화
                                </p>
                                <p class="page-text-sm color-gray dot-txt-sm">
                                    · SSL(secure sockets layer) 트래픽도 평문 트래픽과 <br class="d-none d-lg-block">
                                    동일하게 처리
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="radius-lg bg-grey h-100">
                            <p class="w-100 bg-point color-white text-center font-weight-bold page-text-lg pa-14">
                                VM 기반의 웹 방화벽 (Hardware)
                            </p>
                            <div class="pa-16 pb-0 px-md-42 pt-md-70">
                                <v-img class="d-block mx-auto " max-width="469" src="/images/sub/products/web/s2-img2.png"></v-img>
                            </div>
                            <div class="pa-16 px-md-40 pt-md-40 px-lg-70 pt-lg-70 pb-md-100">
                                <div class="dot-txt-sm mb-8 mb-lg-24">
                                    <p class="page-text-sm color-gray mb-4 mb-lg-10">
                                        · 다수의 웹 서버 앞 단에 위치하는 웹 방화벽은 트래픽 부하 이슈
                                    </p>
                                    <p class="font-size-12 font-size-lg-16 color-gray bar-txt line-height-15">
                                        - 웹 방화벽에서 Request Data에 대한 해킹 검사를 위한 Connection
                                    </p>
                                    <p class="font-size-12 font-size-lg-16 color-gray bar-txt line-height-15">
                                        - 웹 서버에서 프로세스 처리를 위한 Connectio
                                    </p>
                                    <div class="bar-txt">
                                        <p class="font-size-12 font-size-lg-16 color-gray line-height-15">
                                            - 웹 방화벽에서 Response Data에 대한 검사를 위한 Connection
                                        </p>
                                        <p class="font-size-12 font-size-lg-16 color-gray arrow-txt line-height-15">
                                            → 하나의 Process에 4번의 Connection이 발생하여 웹 서비스 속도 저하 현상이 발생
                                        </p>
                                    </div>
                                </div>
                                <div class="dot-txt-sm">
                                    <p class="page-text-sm color-gray mb-4 mb-lg-10">
                                        · 시간당 처리능력에 따라 네트워크 별로 별도 구성되어야 함
                                    </p>
                                    <p class="font-size-12 font-size-lg-16 color-gray bar-txt line-height-15">
                                        - 네트워크 구성이 복잡해짐
                                    </p>
                                    <p class="font-size-12 font-size-lg-16 color-gray bar-txt line-height-15">
                                        - 갑작스런 트래픽 증가에 따른 네트워크 상의 문제가 발생
                                    </p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                
            </v-container>
        </section>

        <section class="sub-section-sm sub-section--last">
            <v-container>

                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    시장에서 검증된 F1-WEBCastle의 주요 기능
                </h3>

                <v-row>
                    <v-col cols="12" md="6" lg="4">
                        <div class="radius-md border-light h-100" data-aos="fade-right" data-aos-delay="100">
                            <div class="py-28 py-lg-40 d-flex justify-center">
                                <v-img class="d-block " max-width="65" src="/images/sub/products/web/s3-icon1-1.png"></v-img>
                            </div>
                            <p class="text-center font-weight-bold border-top border-bottom border-color-light bg-grey color-point page-text-sm pa-12">
                                보안관제 및 통합관리
                            </p>
                            <div class="pa-16 pa-md-24">
                                <p class="color-gray text-center page-text-sm">
                                    관리자가 동일한 UI화면에서 다수의 <br class="d-none d-lg-block">
                                    웹 서버 현황 및 사이버 공격을 <br class="d-none d-lg-block">
                                    실시간으로 파악할 수 있으며, <br class="d-none d-lg-block">
                                    전체적인 통계를 한눈에 파악할 수 있는 <br class="d-none d-lg-block">
                                    대시보드를 제공합니다.
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div class="radius-md border-light h-100" data-aos="fade-right" data-aos-delay="400">
                            <div class="py-28 py-lg-40 d-flex justify-center">
                                <v-img class="d-block " max-width="65" src="/images/sub/products/web/s3-icon1-2.png"></v-img>
                            </div>
                            <p class="text-center font-weight-bold border-top border-bottom border-color-light bg-grey color-point page-text-sm pa-12">
                                중앙서버 - 에이전트 간 접속보안관리
                            </p>
                            <div class="pa-16 pa-md-24">
                                <p class="color-gray text-center page-text-sm">
                                    중앙 서버 관리자와 <br class="d-none d-lg-block">
                                    웹캐슬 에이전트 간에 주고받는 <br class="d-none d-lg-block">
                                    트래픽을 SSL로 암호화하여 <br class="d-none d-lg-block">
                                    중간에 도청할 수 없도록 <br class="d-none d-lg-block">
                                    보안 기능을 제공합니다. 
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div class="radius-md border-light h-100" data-aos="fade-right" data-aos-delay="700">
                            <div class="py-28 py-lg-40 d-flex justify-center">
                                <v-img class="d-block " max-width="65" src="/images/sub/products/web/s3-icon1-3.png"></v-img>
                            </div>
                            <p class="text-center font-weight-bold border-top border-bottom border-color-light bg-grey color-point page-text-sm pa-12">
                                일관된 보안정책설정
                            </p>
                            <div class="pa-16 pa-md-24">
                                <p class="color-gray text-center page-text-sm">
                                    회사별, 그룹별로 보안정책에 <br class="d-none d-lg-block">
                                    따른 일관된 보안정책 설정, <br class="d-none d-lg-block">
                                    접속을 통제하거나 위협요소를 <br class="d-none d-lg-block">
                                    차단할 수 있는 보안관리 <br class="d-none d-lg-block">
                                    기능을 제공합니다.
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div class="radius-md border-light h-100" data-aos="fade-right" data-aos-delay="1000">
                            <div class="py-28 py-lg-40 d-flex justify-center">
                                <v-img class="d-block " max-width="65" src="/images/sub/products/web/s3-icon1-4.png"></v-img>
                            </div>
                            <p class="text-center font-weight-bold border-top border-bottom border-color-light bg-grey color-point page-text-sm pa-12">
                                SSL 적용시 성능저하 없는 탐지 · 방어 솔루션
                            </p>
                            <div class="pa-16 pa-md-24">
                                <p class="color-gray text-center page-text-sm">
                                    개정된 개인정보보호법에 따른 <br class="d-none d-lg-block">
                                    안전한 웹통신을 위해 SSL <br class="d-none d-lg-block">
                                    (Secure Socket Layer) 사용률 급증 <br class="d-none d-lg-block">
                                    / SSL Traffic을 이용한 웹 공격 대응 <br class="d-none d-lg-block">
                                    및 방어 시에 성능저하가 없습니다.
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div class="radius-md border-light h-100" data-aos="fade-right" data-aos-delay="1300">
                            <div class="py-28 py-lg-40 d-flex justify-center">
                                <v-img class="d-block " max-width="65" src="/images/sub/products/web/s3-icon1-5.png"></v-img>
                            </div>
                            <p class="text-center font-weight-bold border-top border-bottom border-color-light bg-grey color-point page-text-sm pa-12">
                                세분화된 로그관리
                            </p>
                            <div class="pa-16 pa-md-24">
                                <p class="color-gray text-center page-text-sm">
                                    보안정책에 따른 관리자 및 공격자에 대한 <br class="d-none d-lg-block">
                                    로그인 기록, 이용기록, 이력관리, <br class="d-none d-lg-block">
                                    탐지 및 차단 이력 등 관련된 <br class="d-none d-lg-block">
                                    전체 로그가 남아 문제가  발생시 <br class="d-none d-lg-block">
                                    선제 대응할 수 있습니다.
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div class="radius-md border-light h-100" data-aos="fade-right" data-aos-delay="1600">
                            <div class="py-28 py-lg-40 d-flex justify-center">
                                <v-img class="d-block " max-width="65" src="/images/sub/products/web/s3-icon1-6.png"></v-img>
                            </div>
                            <p class="text-center font-weight-bold border-top border-bottom border-color-light bg-grey color-point page-text-sm pa-12">
                                원격지 통합보안관리
                            </p>
                            <div class="pa-16 pa-md-24">
                                <p class="color-gray text-center page-text-sm">
                                    인터넷만 연결이 되어있으면 <br class="d-none d-lg-block">
                                    국내외 어느 곳에 위치한 웹 서버라도 <br class="d-none d-lg-block">
                                    온라인으로 설치 및 운영이 가능하며, <br class="d-none d-lg-block">
                                    보안정책에 따라 <br class="d-none d-lg-block">
                                    통합보안관리가 가능합니다.
                                </p>
                            </div>
                        </div>
                    </v-col>
                </v-row>

            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
    /* Title */
    .tit-txt-wrap{
        margin-bottom: 40px;
    }
    .tit-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .tit-txt-wrap .tit-wrap{
        margin-bottom: 10px;
    }
    .tit{
        width: 100%;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 1.2;
        word-break: keep-all;
    }
    .tit.font-notoserif{
        line-height: 1.4;
    }
    .tit--xs{
        font-size: 1.8rem;
        font-weight: 600;
    }
    .tit--sm{
        font-size: 2rem;
    }
    .tit--lg{
        font-size: 3.2rem;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 38px;
    }
    .tit-txt{
        font-size: 1.4rem;
    }


    @media (min-width:576px){
    }
    @media (min-width:768px){
        .tit{
            font-size: 3rem;
        }
        .tit--xs{
            font-size: 1.8rem;
        }
        .tit--lg{
            font-size: 4rem;
            line-height: 50px;
        }
    }
    @media (min-width:1024px){
        .tit-txt-wrap{
            margin-bottom: 60px;
        }
        .tit-wrap{
            margin-bottom: 60px;
        }
        .tit-txt-wrap .tit-wrap{
            margin-bottom: 20px;
        }
        .tit{
            font-size: 3.6rem;
        }
        .tit--xs{
            font-size: 2rem;
        }
        .tit--sm{
            font-size: 2.4rem;
        }
        .tit--lg{
            font-size: 5.2rem;
            line-height: 66px;
            margin-bottom: 72px
        }
        .tit-txt{
            font-size: 1.6rem;
        }
    }
    @media (min-width:1200px){
    }
/* Page Text */
.page-text{
    font-size: 1.6rem;
    line-height: 24px;
}
.page-text-sm{
    font-size: 1.4rem;
    line-height: 20px;
}
.page-text-lg{
    font-size: 1.6rem;
    line-height: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .page-text{
        font-size: 2rem;
        line-height: 30px;
    }
    .page-text-sm{
        font-size: 1.8rem;
        line-height: 28px;
    }
    .page-text-lg{
        font-size: 2.2rem;
        line-height: 30px;
    }
}
@media (min-width:1200px){
}
</style>